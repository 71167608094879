// A set of helper compass mixins

// Hides the element off the page, but still keeps is accessible to those using screen readers.
@mixin accessible_hidden {
  position:absolute;
  left:-999em;
}

// Jeffrey Seldman's New Image Replacement snippet.
// http://www.zeldman.com/2012/03/01/replacing-the-9999px-hack-new-image-replacement/
@mixin image_replacement {
  text-indent:100%;
  white-space: nowrap;
  overflow:hidden;
}

// Based on Nicholas Gallagher's micro-clearfix. Apply directly to the forehead of the element that is collapsing.
// http://nicolasgallagher.com/micro-clearfix-hack/
@mixin clearfix {
  &:before, &:after {
    content:" ";
    display:table;
  }
  &:after {
    clear:both;
  }
  & {
    *zoom:1;
  }
}

@mixin aspect-ratio($width, $height) {
  position: relative;
  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: ($height / $width) * 100%;
  }
  > .ratio-content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

// e.g.
// @include ie_translucent_background(#ffffff, 'CC');
@mixin ie_translucent_background($color, $opacity){
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='##{$opacity}#{drop-hex-hash($color)}',endColorstr='##{$opacity}#{drop-hex-hash($color)}');
}

// Private functions

@function drop-hex-hash($string) {
  $string: inspect($string);
  $string: str-slice($string, 2, str-length($string));
  @return $string;
}

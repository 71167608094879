.navbar {
  // position: fixed;
  background-color: $dark;
  width: 100%;
  padding: .5em 1em;
}

.navbar__nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar__logo {
  display: block;
  width: 200px;
  @include breakpoint($third-breakpoint) {
    width: 150px;
  }
}

.navbar__social-media {
  font-size: 2em;
  @include breakpoint($third-breakpoint) {
    font-size: 1.5em;
  }
}

.join-the-movement {
  background-color: $yellow;
  text-align: center;
  padding: 2em 1em;
}

.join-the-movement__heading {
  margin: 0 0 1em 0;;
  text-transform: uppercase;
  font-size: 1.5em;
}

.join-the-movement__hashtag {
  font-size: 4em;
  font-weight: normal;
  letter-spacing: 5px;
  font-style: italic;
  text-shadow: 2px 2px lighten($dark, 20%);
  margin: 0;

  @include breakpoint($second-breakpoint) {
    font-size: 2em;
  }

  @include breakpoint($third-breakpoint) {
    font-size: 1.25em;
  text-shadow: 1px 1px lighten($dark, 20%);
  }
}

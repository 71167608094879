.skipmenu { position: relative; }

.skipmenu__item {
  position: absolute;
  top: -1000px;
  left: -1000px;
  height: 1px;
  width: 1px;
  text-align: left;
  overflow: hidden;
  -webkit-user-select: none;
  -webkit-user-drag: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  &:active,
  &:focus,
  &:hover {
    top: 0;
    left: 0;
    font-size: 1em;
    font-weight: 700;
    color: #fff;
    background-color: #ff3333;
    height: auto;
    overflow: auto;
    width: 100%;
    padding: 5px;
    z-index: 15;
  }
}

.contentinfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  padding: .5em 1em;
  background-color: $dark;
}

.contentinfo__text {
  font-size: .875em;
}

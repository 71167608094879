@font-face {
	font-family: 'base-icon-fonts';
	src:url('/app/fonts/base-icon-fonts/base-icon-fonts.eot?fjrq9e');
	src:url('/app/fonts/base-icon-fonts/base-icon-fonts.eot?#iefixfjrq9e') format('embedded-opentype'),
		url('/app/fonts/base-icon-fonts/base-icon-fonts.ttf?fjrq9e') format('truetype'),
		url('/app/fonts/base-icon-fonts/base-icon-fonts.woff?fjrq9e') format('woff'),
		url('/app/fonts/base-icon-fonts/base-icon-fonts.svg?fjrq9e#base-icon-fonts') format('svg');
	font-weight: normal;
	font-style: normal;
}

[class^="base-icon-"], [class*=" base-icon-"] {
	font-family: 'base-icon-fonts';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.base-icon-untappd:before {
	content: "\e617";
}
.base-icon-home:before {
	content: "\e600";
}
.base-icon-phone:before {
	content: "\e601";
}
.base-icon-location:before {
	content: "\e602";
}
.base-icon-search:before {
	content: "\e603";
}
.base-icon-menu:before {
	content: "\e604";
}
.base-icon-close:before {
	content: "\e605";
}
.base-icon-checkmark:before {
	content: "\e606";
}
.base-icon-play2:before {
	content: "\e616";
}
.base-icon-play:before {
	content: "\e607";
}
.base-icon-pause:before {
	content: "\e608";
}
.base-icon-stop:before {
	content: "\e609";
}
.base-icon-facebook:before {
	content: "\e60a";
}
.base-icon-twitter:before {
	content: "\e60b";
}
.base-icon-feed:before {
	content: "\e60c";
}
.base-icon-youtube:before {
	content: "\e60d";
}
.base-icon-vimeo:before {
	content: "\e60e";
}
.base-icon-flickr:before {
	content: "\e60f";
}
.base-icon-github:before {
	content: "\e610";
}
.base-icon-tumblr:before {
	content: "\e611";
}
.base-icon-skype:before {
	content: "\e612";
}
.base-icon-reddit:before {
	content: "\e613";
}
.base-icon-pinterest:before {
	content: "\e614";
}
.base-icon-file-pdf:before {
	content: "\e615";
}
.base-icon-search-plus:before {
	content: "\f00e";
}
.base-icon-search-minus:before {
	content: "\f010";
}
.base-icon-chevron-left:before {
	content: "\f053";
}
.base-icon-chevron-right:before {
	content: "\f054";
}
.base-icon-chevron-up:before {
	content: "\f077";
}
.base-icon-chevron-down:before {
	content: "\f078";
}
.base-icon-google-plus:before {
	content: "\f0d5";
}
.base-icon-envelope:before {
	content: "\f0e0";
}
.base-icon-linkedin:before {
	content: "\f0e1";
}
.base-icon-instagram:before {
	content: "\f16d";
}
